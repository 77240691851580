import formInstance from 'store/modules/formInstance'
import formHelperMixin from '@/components/form/mixins/formHelperMixin'
import formSaveMixin from '@/components/form/mixins/formSaveMixin'
export default {
  mixins: [formSaveMixin, formHelperMixin],
  metaInfo() {
    return {
      title: this.pageTitle,
    }
  },
  data: (vm) => ({
    formKey: `form-${vm._uid}`,
    hasInit: false,
    bindRoute: true,
    dataLoaded: false,
    pageTitle: '',
  }),
  beforeDestroy() {
    this.$root.$off(`form/${this.formKey}/beforeSave`)
  },
  async created() {
    await this._initForm()
  },
  methods: {
    getFormConfig() {
      return {
        get: (vm) => {},
      }
    },
    getMode() {
      // 強制設定表單mode(避免在同頁面多個表單時候都綁到同個route.meta的mode)
      if(typeof this.forceSetMode === 'function') {
        return this.forceSetMode()
      }
      if(this.bindRoute) return this.$route.meta.formMode
      return this.getFormConfig().mode
    },
    async _initForm() {
      this._initVuexModule()
      this.$store.dispatch(`form/${this.formKey}/set`, {
        key: 'mode',
        value: this.getMode()
      })
      this.$store.dispatch(`form/${this.formKey}/setApi`, {
        readApi: this.readApi,
        createApi: this.createApi,
        updateApi: this.updateApi,
        deleteApi: this.deleteApi,
      })

      this.$store.dispatch(`form/${this.formKey}/setActions`, {
        setupPageTitle: () => {
          this.setupPageTitle()
          this._setupBreadcrumb()
        },
        save: this.save,
        validateBeforeSave: this.validateBeforeSave,
        initForm: this._initForm,
        resetFormData: this.resetFormData,
      })

      this.setupPageTitle()
      await this._setupBreadcrumb()
      await this._setupMeta()
      this.dataLoaded = false
      await this._initFormData()
      this.dataLoaded = true
      this.formActions.setupPageTitle()
    },
    resetFormData(data) {
      const formData = window.eagleLodash.cloneDeep(data)
      this.$store.dispatch(`form/${this.formKey}/setOriginalData`, formData)
      this.$store.dispatch(`form/${this.formKey}/setData`, formData)
    },
    async _setupMeta() {
      if(typeof this.beforeFormInit != 'function') {
        this.$store.dispatch(`form/${this.formKey}/setMeta`, null)
        return
      }
      try {
        await this.beforeFormInit()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch(`form/${this.formKey}/setMeta`, window.eagleLodash.cloneDeep(this.meta))
      }
    },
    async _initFormData() {
      this.$store.dispatch(`form/${this.formKey}/set`, { key: 'loadFailure', value: false })
      // 新增或不需讀取
      if(this.formMode === 'create' || this.formConfig.disableRead === true) {
        const formDefaultData = typeof this.formDefaultData === 'function'
          ? this.formDefaultData()
          : this.formDefaultData
        this.$store.dispatch(`form/${this.formKey}/setOriginalData`, formDefaultData)
        this.$store.dispatch(`form/${this.formKey}/setData`, formDefaultData)
        return
      }

      // 更新
      this.$store.dispatch('loading/active')
      this.$store.dispatch('loading/progress')
      try {
        const result = await this.readApi(this.target)
        this.$store.dispatch(`form/${this.formKey}/setOriginalData`, result)
        this.$store.dispatch(`form/${this.formKey}/setData`, result)
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('data_load.failure.content'),
          this.$t('data_load.failure'),
        )
        this.$store.dispatch(`form/${this.formKey}/set`, { key: 'loadFailure', value: true })
      } finally {
        this.$store.dispatch('loading/close')
        this.$store.dispatch('loading/closeProgress')
      }
    },
    _initVuexModule() {
      this.hasInit = false
      if(this._hasModule()) {
        this._destroyVuexModule()
      }
      if(this._hasModule() === false) {
        const options = {
          bindRoute: this.bindRoute,
          config: this.getFormConfig().get(this, this.formKey),
        }
        options.hasWritePermission = this.checkHasWritePermission(options.config)
        this.$store.registerModule(['form', this.formKey], formInstance(options))
      }
      this.$store.dispatch(`form/${this.formKey}/reset`)
      this.hasInit = true
    },
    setupPageTitle() {
      if(!this.formConfig) return
      if(!this.formConfig.pageTitle) return

      // page title callback
      if(typeof this.formConfig.pageTitle != 'function') {
        this.pageTitle = this.$t(this.formConfig.pageTitle) || ''
        return
      }


      const formData = !this.formData ? {} : window.eagleLodash.cloneDeep(this.formData)
      let pageTitle = this.formConfig.pageTitle(this.formMeta, formData)
      if(typeof this.formConfig.pageTitleBase != 'function') {
        this.pageTitle = this.$t(pageTitle)
        return
      }

      let pageTitleBase = this.$t(this.formConfig.pageTitleBase(this.formMeta, this.formData))
      if(!pageTitle) {
        this.pageTitle = pageTitleBase
        return
      }
      this.pageTitle = `${pageTitle} (${pageTitleBase})`
    },
  },
  watch: {
    target: {
      handler() {
        this._initForm()
      },
    },
    meta: {
      deep: true,
      handler() {
      },
    },
  },
}
