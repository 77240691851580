<template>
  <v-form
    eagle-form="root"
    @submit.prevent
    lazy-validation
    v-if="formConfig"
    :ref="ref"
    :bind-route="bindRoute"
    :style="formStyle"
  >
    <formMetaAction
      v-if="hasWritePermission"
      :formKey="formKey"
    ></formMetaAction>

    <!-- 一般layer mode -->
    <v-tabs
      v-if="isLayerMode"
      v-model="focusTab"
    >
      <formTab
        v-for="(tab, tabIndex) in tabs"
        :key="`form-tab-${tabIndex}`"
        :tab="tab"
        :index="tabIndex"
        :form-key="formKey"
      ></formTab>
    </v-tabs>

    <!-- layer mode -->
    <formLayers
      :form-key="formKey"
      v-model="focusTab"
    ></formLayers>

    <!-- 一般block mode -->
    <formBlocksContainer
      v-if="formData && !isLayerMode"
      :form-key="formKey"
    ></formBlocksContainer>

    <formAction
      :form-key="formKey"
    ></formAction>

    <!--找不到資料-->
    <data-not-found
      v-if="loadFailure"
    ></data-not-found>
  </v-form>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  data: (vm) => ({
    focusTab: vm.$route.query.tab,
  }),
  created() {
    this.$root.$on(`form/${this.formKey}/submit`, this.onSubmit)
  },
  methods: {
    onFormChangedStatusUpdate() {
      if(!this.bindRoute) return // 沒綁路由就不須管關閉或離開頁面
      if(this.hasChanged) {
        window.onbeforeunload = (event) => {
          const message = this.$t('exit.confirm')
          event.returnValue = message
          return message
        }
      }

      else {
        window.onbeforeunload = null
      }

      window.vueRouteChangePrevent.setup(this.formKey, this.hasChanged)
    },
    onSubmit() {
      const $form = this.$refs[this.ref]
      if(!$form) return
      $form.validate()
    },
  },
  computed: {
    routeQueryTab() {
      return this.$route.query.tab
    },
    tabs() {
      if(!this.isLayerMode) return null
      return this.formConfig.tabs
    },
    isLayerMode() {
      if(!Array.isArray(this.formConfig.tabs)) {
        return false
      }
      return !!this.formConfig.layers
    },
    hasWritePermission() {
      return this.$store.getters[`form/${this.formKey}/hasWritePermission`]
    },
    loadFailure() {
      return this.$store.getters[`form/${this.formKey}/loadFailure`]
    },
    bindRoute() {
      return this.$store.getters[`form/${this.formKey}/bindRoute`]
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    hasChanged() {
      return this.$store.getters[`form/${this.formKey}/hasChanged`]
    },
    ref() {
      return `eagle-form-${this.formKey}`
    },
    formStyle() {
      return {
        minHeight: this.minHeight
      }
    },
    minHeight() {
      return this.formConfig.minHeight || '80vh'
    },
  },
  watch: {
    hasChanged: {
      immediate: true,
      handler() {
        this.onFormChangedStatusUpdate()
      },
    },
    routeQueryTab: {
      handler() {
        if(this.routeQueryTab) this.focusTab = this.routeQueryTab
      },
    },
  },
  components: {
    formTab: () => import('@/components/form/formTab.vue'),
    formLayers: () => import('@/components/form/formLayers.vue'),
    formMetaAction: () => import('@/components/form/formMetaAction.vue'),
    formBlocksContainer: () => import('@/components/form/formBlocksContainer.vue'),
    formAction: () => import('@/components/form/formAction.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
